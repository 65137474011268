import { AppRouterCacheProvider } from "@mui/material-nextjs/v14-appRouter";
import { ReactNode } from "react";

export type HKECacheProviderProps = { children: ReactNode; pageRouter?: boolean };

/**
 * Control the Global style injection
 * MUI - https://mui.com/material-ui/integrations/nextjs/
 */
export const HKECacheProvider = ({ children, pageRouter, ...others }: HKECacheProviderProps) => {
  if (pageRouter) {
    return children;
  }
  return (
    <AppRouterCacheProvider options={{ key: "ui-lib-cache", enableCssLayer: true }}>{children}</AppRouterCacheProvider>
  );
};
