import { createAction } from "@reduxjs/toolkit";
import { InitPaymentSaga, LongPollingFormAction } from "lib/features/flight-book/payment/definition";

const namespace = "B2B";

export const USERNAME_STORE = `${namespace}/USERNAME_STORE`;
export const UsernameStore = createAction<boolean>(USERNAME_STORE);

export const PASSWORD_STORE = `${namespace}/PASSWORD_STORE`;
export const PasswordStore = createAction<boolean>(PASSWORD_STORE);

export const STORE_ORIGINAL_PASSWORD = `${namespace}/STORE_ORIGINAL_PASSWORD`;
export const storeOriginalPassword = createAction<boolean>(STORE_ORIGINAL_PASSWORD);

export const INIT_B2B_PAYMENT_SAGA = `${namespace}/INIT_B2B_PAYMENT_SAGA`;
export const initB2BPaymentSaga = createAction<Partial<InitPaymentSaga>>(INIT_B2B_PAYMENT_SAGA);

export const CREATE_B2B_FORM_ACTION = `${namespace}/CREATE_B2B_FORM_ACTION`;
export const createB2BFormAction = createAction<LongPollingFormAction | undefined>(CREATE_B2B_FORM_ACTION);
