import { ArItems, ETripType, Fare, MMBPax, Segment } from "@hkexpressairwayslimited/ui";
import { SSR } from "lib/features/flight-book/payment/definition";

export enum ETripStatus {
  confirmed = "CONFIRMED",
  onHold = "ON_HOLD",
  unsettled = "UNSETTLED",
  flown = "FLOWN",
}

export enum EFareType {
  essential = "essential",
  lite = "lite",
  max = "max",
  ultraLite = "ultraLite",
  fun = "fun",
  funflex = "funflex",
}

interface Duration {
  hour: number;
  minute?: number;
}

export interface TripsItemType {
  flightNumber: string;
  fareType: EFareType | null;
  date: string;
  time: string;
  flightTime: string;
  segments: Segment[];
  journey_eligibility: JourneyEligibility;
}

export interface TripItemType {
  picUrl: string;
  tripType: ETripType;
  tripStatus: ETripStatus;
  destination: string;
  bookingRef: string;
  trips: TripsItemType[];
  departureDateTime: string;
  passengers: {
    adult: number;
    child: number;
    infant: number;
  };
}

//----------------------------------------------Api Types---------------------------------------------------

export type PnrRetrieveBookingRequests = {
  sales_reference: string;
  first_name: string;
  last_name: string;
};

export type APIPnrRetrieveBookingRequestBody = {
  pnr_query_requests: PnrRetrieveBookingRequests[];
};

type ResendItinerary = {
  sales_reference: string;
  recipient_email_passenger_keys: string[];
  last_name: string;
  first_name: string;
  template_language: string;
};

export type APIResendEmailRequestBody = ResendItinerary;
export type APIResendSMSRequestBody = Omit<ResendItinerary, "recipient_email_passenger_keys">;

export type APIGetBookingSSRlRequestBody = {
  sales_reference: string;
  first_name: string;
  last_name: string;
  currency_code: string;
};

type PassengerInfo = {
  title: string;
  date_of_birth: string;
};

type PhoneNumber = {
  country_code: string;
  number: string;
};

type ContactInfo = {
  phone_number: PhoneNumber;
  email_address: string;
};

type TravelDoc = {
  doc_type: string;
  issuing_country: string;
  doc_nationality: string;
  doc_number: string;
  expire_date: string;
};

type MMBPassenger = {
  passenger_key: string;
  info: PassengerInfo;
  contact_info: ContactInfo;
  trave_doc: TravelDoc;
  infant?: {
    info: PassengerInfo;
    travel_doc: TravelDoc;
  };
};

type ContactPerson = {
  phone_number: PhoneNumber;
  first_name: string;
  last_name: string;
  email_address: string;
  title: string;
  is_passenger: boolean;
};

export type APIUpdatePassengerDetailRequestBody = {
  sales_reference: string;
  booking_first_name: string;
  booking_last_name: string;
  passengers: MMBPassenger[];
  contact_person?: ContactPerson;
};

type Total = {
  amount: number;
  currency: string;
  label: string;
};

type Seat = {
  unit_key: string;
  unit_designator: string;
  item_amount: number;
};

type MMBOrderPassenger = {
  passenger_key: string;
  seats: Seat[];
};

export type APIMMBOrderCreateBody = {
  sales_channel: string;
  sales_market: string;
  sales_port: string;
  sales_reference: string;
  selected_currency_code: string;
  customer_country: string;
  cash_amount: number;
  mile_amount: number;
  application_code: string;
  // uo_username: string;
  trip_type: string;
  passengers: MMBOrderPassenger[];
  ssrs: SSR[];
  insurance: {
    item_amount: number;
  } | null;
  carbon_offset: {
    item_amount: number;
  } | null;
  mcp: {
    currency_code: string;
    total_amount: number;
  } | null;
  dcc_key: string | null;
  total: Total;
};

export type OlciEligibility = {
  passenger_key: string;
  olci_eligible: boolean;
  sdk_eligible: boolean;
  eboarding_pass_eligibility: boolean;
};

export type JourneyEligibility = {
  olci_eligibility: boolean;
  olci_start_time: string;
  olci_end_time: string;
  change_flight_eligibility: boolean;
  update_trip_eligibility: boolean;
  eboarding_pass_eligibility: boolean;
  ufirst_eligibility: boolean;
  is_any_passenger_checked_in: boolean;
};
export interface MMBJourney {
  arrival_date_time: string;
  departure_date_time: string;
  eligibilities: OlciEligibility[];
  journey_eligibility: JourneyEligibility;
  index?: number;
  journey_key: string;
  fare: Fare;
  origin: string;
  destination: string;
  date: string;
  duration: Duration;
  segments: Segment[];
  display_item_amount: number;
  flight_time: string;
  day_diff?: number;
  arItems: ArItems;
  olci_nsk_token?: string;
  idle_timeout_second?: number;
  date_time?: string;
}

type PaymentRecord = {
  payment_method_code: string;
  payment_date: string;
  currency_code: string;
  item_amount: number;
  collected_currency_code?: string;
  collected_amount?: number;
  mile_amount?: number;
};

type Contact = {
  first_name: string;
  last_name: string;
  title: string;
  email_address: string;
  phone_number: string;
  country?: string;
  countryCode?: string;
};
export interface ManageMyBookingTripDetail {
  sales_reference: string;
  payment_detail: {
    status: string;
    payment_due_date?: string;
    currency_code?: string;
    balance_due?: number;
  };
  payment_record: PaymentRecord[];
  change_flight_fees_total: number;
  convenience_fees_total: number;
  spoilage_fees_total: number;
  is_customer_editable: boolean;
  is_flight_cancel: boolean;
  is_flight_itinerary_viewable: boolean;
  insurance: {
    is_sold: boolean;
    is_eligible: boolean;
  };
  insurance_fees_total: number;
  carbon_offset: {
    is_sold: boolean;
    is_eligible: boolean;
  };
  carbon_offset_fees_total: number;
  passengers: MMBPax[];
  journeys: MMBJourney[];
  arItems: ArItems;
  contact: Contact;
  isGDS?: boolean;
  agent_eligibility?: {
    cancel_eligibiliy: boolean;
  };
}

export type BookingInfo = {
  sales_reference: string;
  first_name?: string;
  last_name?: string;
};

export interface APIMMBFlightSearchBody extends BookingInfo {
  first_flight?: {
    date: string;
  };
  second_flight?: {
    date: string;
  };
}

export type APIChangeFlightBody = {
  first_journey?: {
    journey_key: string;
    fare_availability_key: string;
  };
  second_journey?: {
    journey_key: string;
    fare_availability_key: string;
  };
};

export interface APIChangeFlightSSRBody extends BookingInfo {
  currency_code: string;
}

export type APIMMBOrderValidationBody = {
  // cx_member_number: string | null;
  // uo_username: string;
  sales_market: string;
  sales_reference: string;
  trip_type: string; //RT / OW / COMPLEX
  selected_currency_code: string;
  customer_country: string;
  application_code: string;
  passengers: MMBOrderPassenger[];
  ssrs: SSR[];
};
