import currency from "currency.js";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { selectCurrencyDetail } from "store/sessionStorage/slices/currency";
const M = require("mathjs");

export function multiply(a: string | number, b: string | number, roundingFactor: number) {
  try {
    const price = parseFloat(M.format(M.multiply(M.bignumber(a), M.bignumber(b))));
    const toFixedFactor = roundingFactor < 1 ? (1 / roundingFactor).toString().length - 1 : 0;
    return parseFloat(
      roundingFactor < 1
        ? price.toFixed(toFixedFactor)
        : (parseInt((price / roundingFactor).toFixed(0)) * roundingFactor).toFixed(0)
    );
  } catch (e) {
    console.error(e);
    return 0;
  }
}

export function useCurrency() {
  const {
    selectedCurrency,
    bookingCurrency,
    asiaMiles,
    currentRate = 1,
    roundingFactor = 1,
  } = useSelector(selectCurrencyDetail);
  const toFixedFactor = roundingFactor < 1 ? (1 / roundingFactor).toString().length - 1 : 0;
  return {
    D: useCallback(
      (amount: string | number, from: string = bookingCurrency, to: string = selectedCurrency) => {
        const nAmount = parseFloat(amount + "");
        if (from === to) {
          return {
            toCurrency: to,
            fromCurrency: from,
            rate: 1,
            price: nAmount,
          };
        }

        return {
          toCurrency: to,
          fromCurrency: from,
          rate: currentRate,
          originPrice: nAmount,
          price: nAmount ? multiply(nAmount, currentRate, roundingFactor) : 0,
        };
      },
      [bookingCurrency, currentRate, roundingFactor, selectedCurrency]
    ),
    P: useCallback(
      (
        amount: string | number | undefined,
        forceCurrency: string | undefined = undefined,
        isString: boolean = false,
        forceRate: number = 0,
        bold: boolean = false,
        masking?: boolean
      ) => {
        const isNaNResult = parseInt(amount + "");
        if (isNaN(isNaNResult)) return isNaNResult + "";

        const price = amount ? multiply(amount, forceRate || currentRate, roundingFactor) : 0;
        if (isString)
          return `${asiaMiles && forceCurrency === undefined ? `<i class='demo-icon icon-icon-asiamiles.originalimage-1'>&#xe800;</i>` : forceCurrency ?? selectedCurrency}${
            masking
              ? "****"
              : currency(price, { precision: toFixedFactor }).format({
                  symbol: " ",
                  separator: ",",
                  decimal: ".",
                })
          }`;
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: bold ? "600" : "500",
            }}
          >
            <div style={{ marginRight: "4px" }}>
              {asiaMiles && forceCurrency === undefined ? (
                <i className='demo-icon icon-icon-asiamiles.originalimage-1'>&#xe800;</i>
              ) : (
                forceCurrency ?? selectedCurrency
              )}
            </div>
            <div>
              {masking
                ? "****"
                : currency(price, { precision: toFixedFactor }).format({
                    symbol: "",
                    separator: ",",
                    decimal: ".",
                  })}
            </div>
          </div>
        );
      },
      [asiaMiles, currentRate, roundingFactor, selectedCurrency, toFixedFactor]
    ),
  };
}
