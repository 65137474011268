import { EPaxType, ETripType, IBEPax, TripDetail } from "@hkexpressairwayslimited/ui";
import { getFlightRoute } from "lib/features/flight-book/booking/service";
import { getAllSSR, getSeats } from "lib/features/flight-book/payment/helper";
import { getLangLocale } from "modules/common/common/utils/i18n-utils";
import store from "store";
import { ChangeFlightJourneyDate, Fees } from "store/sessionStorage/slices/manageMyBookingSlice";
import { ManageMyBookingTripDetail } from "../definition";

export const constructMMBOrderValidationBody = async (booking: ManageMyBookingTripDetail, appCode?: string) => {
  const sales_port_grouping = (await getFlightRoute()).sales_port_grouping;
  // const uoUsername = parseJwt(store.getState().auth.accessToken).username;
  const ssrs = getAllSSR(booking);
  return {
    // cx_member_number: null,
    // uo_username: uoUsername,
    sales_market:
      sales_port_grouping.find((route: any) =>
        route.ports.some((port: any) => port.airport_code === booking.journeys[0].origin)
      )?.market ?? "",
    sales_reference: booking.sales_reference,
    trip_type:
      booking.journeys.length === 1
        ? "OW"
        : booking.journeys[0].origin === booking.journeys[1].destination
          ? "RT"
          : "COMPLEX",
    selected_currency_code: booking.journeys[0].fare.currency_code,
    customer_country: booking?.contact?.country ?? booking.passengers[0]?.country ?? "",
    application_code: appCode ? appCode : store.getState().session.nskToken.app_code,
    passengers: booking.passengers
      .filter((passenger) => passenger.paxType !== EPaxType.Infant)
      .map((passenger) => ({
        passenger_key: passenger.id,
        seats: getSeats(passenger.id, booking),
      })),
    ssrs: ssrs,
    language: getLangLocale().toUpperCase(),
  };
};
export const constructMMBOrderCreateBody = async ({
  booking,
  mileAmount,
  cash_amount,
  currency,
  tripCost,
  changeFlightTrip,
  appCode,
  insurance,
  carbon_offset,
  pay_by_organization_credit,
}: {
  booking: ManageMyBookingTripDetail;
  mileAmount: number;
  cash_amount: number;
  currency: string;
  tripCost: number;
  changeFlightTrip?: TripDetail & {
    passengers: IBEPax[];
    notTransferSSR: any[];
    fees: Fees;
    changeFlightJourneyDate: {
      first_journey?: ChangeFlightJourneyDate;
      second_journey?: ChangeFlightJourneyDate;
    };
  };
  appCode?: string;
  insurance?: number;
  carbon_offset?: number;
  pay_by_organization_credit?: boolean;
}) => {
  const sales_port_grouping = (await getFlightRoute()).sales_port_grouping;
  // const uoUsername = parseJwt(store.getState().auth.accessToken).username;
  const ssrs = getAllSSR(changeFlightTrip ?? booking);
  return {
    sales_channel: "ONLINE",
    sales_market:
      sales_port_grouping.find((route: any) =>
        route.ports.some((port: any) => port.airport_code === booking.journeys[0].origin)
      )?.market ?? "",
    sales_port: booking.journeys[0].origin,
    sales_reference: booking.sales_reference,
    selected_currency_code: currency,
    customer_country: booking?.contact?.country ?? booking.passengers[0]?.country ?? "",
    cash_amount: cash_amount,
    mile_amount: mileAmount ?? 0,
    application_code: appCode ? appCode : store.getState().session.nskToken.app_code,
    // uo_username: uoUsername,

    trip_type:
      booking.journeys.length === 1
        ? "OW"
        : booking.journeys[0].origin === booking.journeys[1].destination
          ? "RT"
          : "COMPLEX",

    mcp: null,
    language: getLangLocale().toUpperCase(),
    passengers: booking.passengers
      .filter((passenger) => passenger.paxType !== EPaxType.Infant)
      .map((passenger) => ({
        passenger_key: passenger.id,
        seats: getSeats(passenger.id, changeFlightTrip ?? booking),
      })),
    ssrs: ssrs,
    insurance: insurance ? { item_amount: insurance } : null,
    carbon_offset: carbon_offset ? { item_amount: carbon_offset } : null,
    // total: {
    //   amount: tripCost,
    //   currency: booking.journeys[0].fare.currency_code,
    //   label: "Grand total (including taxes)",
    // },
    pay_by_organization_credit: pay_by_organization_credit,
  };
};

export const checkTripType = (booking: TripDetail | ManageMyBookingTripDetail | undefined) => {
  if (booking && booking.journeys) {
    return booking.journeys.length === 1
      ? ETripType.oneWay
      : booking.journeys[0].origin === booking.journeys[1].destination &&
          booking.journeys[1].origin === booking.journeys[0].destination
        ? ETripType.roundTrip
        : ETripType.multiCity;
  }
  return ETripType.oneWay;
};

export const constructChangeFlightOrderValidationBody = async (
  changeFlightTrip: TripDetail & {
    passengers: IBEPax[];
    notTransferSSR: any[];
    fees: Fees;
    changeFlightJourneyDate: {
      first_journey?: ChangeFlightJourneyDate;
      second_journey?: ChangeFlightJourneyDate;
    };
  },
  bookingTrip: ManageMyBookingTripDetail
) => {
  // const uoUsername = parseJwt(store.getState().auth.accessToken).username;
  const ssrs = getAllSSR(changeFlightTrip);
  const sales_port_grouping = (await getFlightRoute()).sales_port_grouping;

  const body = {
    // cx_member_number: null,
    // uo_username: uoUsername,
    sales_market:
      sales_port_grouping.find((route: any) =>
        route.ports.some((port: any) => port.airport_code === bookingTrip.journeys[0].origin)
      )?.market ?? "",
    sales_reference: bookingTrip.sales_reference,
    trip_type:
      bookingTrip.journeys.length === 1
        ? "OW"
        : bookingTrip.journeys[0].origin === bookingTrip.journeys[1].destination
          ? "RT"
          : "COMPLEX",
    selected_currency_code: bookingTrip.journeys[0].fare.currency_code,
    customer_country: bookingTrip.contact?.country ?? bookingTrip.passengers[0]?.country ?? "",
    application_code: "MMB",
    passengers: bookingTrip.passengers
      .filter((passenger) => passenger.paxType !== EPaxType.Infant)
      .map((passenger) => ({
        passenger_key: passenger.id,
        seats: getSeats(passenger.id, changeFlightTrip),
      })),
    ssrs: ssrs,
    language: getLangLocale().toUpperCase(),
  };

  return {
    ...body,
    ...changeFlightTrip.changeFlightJourneyDate,
  };
};

export const constructUnsettledPaymentOrderCreationBody = async (
  booking: ManageMyBookingTripDetail,
  cash_amount: number,
  pay_by_organization_credit?: boolean
) => {
  const sales_port_grouping = (await getFlightRoute()).sales_port_grouping;
  // const uoUsername = parseJwt(store.getState().auth.accessToken).username;
  return {
    language: getLangLocale().toUpperCase(),
    sales_channel: "ONLINE",
    sales_market:
      sales_port_grouping.find((route: any) =>
        route.ports.some((port: any) => port.airport_code === booking.journeys[0].origin)
      )?.market ?? "",
    sales_port: booking.journeys[0].origin,
    sales_reference: booking.sales_reference,
    selected_currency_code: booking.journeys[0].fare.currency_code,
    customer_country: booking.contact?.country ?? booking.passengers[0]?.country ?? "",
    // uo_username: uoUsername,
    cash_amount,
    pay_by_organization_credit,
  };
};
