import { ArItem, EFont, ESpacing, ETripType, Font, IBEPax, TripDetail } from "@hkexpressairwayslimited/ui";
import { Grid } from "@mui/material";
import { uniqBy } from "lodash";
import { useTransContent } from "modules/common/trans-content/transContent";
import { SelectedArItems } from "modules/features/flightBooking/reducers";
import { useCurrency } from "modules/global/currency/useCurrency";
import { useMemo } from "react";
import {
  OnSimCardCloseData,
  OnSimCardSelectedData,
  OnSportSelectedData,
  OnUFirstCloseData,
  OnUFirstSelectedData,
  SportCloseEvent,
} from ".";
import { EOtherArCode } from "../d";
import { SimCardSection } from "./SimCardSelector";
import { SportSection } from "./SportSelector";
import { UFirstSection } from "./UFirstSelector";

const orderMapping: { [k in EOtherArCode]: number } = {
  [EOtherArCode.U_FIRST]: 1,
  [EOtherArCode.SIM_CARD]: 2,
  [EOtherArCode.SIM_CARD_B]: 2,
  [EOtherArCode.SPORT_EQUIPMENT]: 3,
};
export function OtherSection({
  tripDetail,
  tripType,
  passengers,
  selectedSportArItem,
  onSportConfirmed,
  onSportSelected,
  onSportClose,
  selectedUFirstArItem,
  onUFirstConfirmed,
  onUFirstSelected,
  selectedSimCardArItem,
  onSimCardConfirmed,
  onSimCardSelected,
  onSimCardClose,
  simConfirmed,
  uFirstConfirmed,
  sportConfirmed,
  onOpenSportDrawer,
  onOpenUFirstDrawer,
  onUFirstCardClose,
  onOpenSimCardDrawer,
}: {
  tripDetail: TripDetail;
  tripType: ETripType;
  passengers: IBEPax[];
  selectedSportArItem: SelectedArItems[];
  onSportConfirmed?: (b: boolean) => void;
  onSportSelected: (data: OnSportSelectedData) => void;
  onSportClose?: SportCloseEvent;
  selectedUFirstArItem: SelectedArItems[];
  onUFirstConfirmed?: (b: boolean) => void;
  onUFirstSelected: (data: OnUFirstSelectedData) => void;
  selectedSimCardArItem: SelectedArItems[];
  onSimCardConfirmed?: (b: boolean) => void;
  onSimCardSelected?: (data: OnSimCardSelectedData) => void;
  onSimCardClose?: (data: OnSimCardCloseData) => void;
  simConfirmed?: boolean;
  uFirstConfirmed?: boolean;
  sportConfirmed?: boolean;
  onOpenSportDrawer?: () => void;
  onOpenUFirstDrawer?: () => void;
  onUFirstCardClose?: (data: OnUFirstCloseData) => void;
  onOpenSimCardDrawer?: () => void;
}) {
  const { P } = useCurrency();
  const othersType = Object.values(EOtherArCode);
  const otherArItem = useMemo(() => {
    return uniqBy(
      tripDetail.arItems?.available
        .concat(tripDetail.arItems?.purchased || [])
        .filter((e) => othersType.includes(e.code as EOtherArCode))
        .concat(
          tripDetail.journeys.reduce<ArItem[]>(
            (acc, curt) => [
              ...acc,
              ...curt.arItems.available.filter((e) => othersType.includes(e?.code as EOtherArCode)),
              ...curt.arItems.purchased.filter((e) => othersType.includes(e?.code as EOtherArCode)),
            ],
            []
          )
        ),
      (e) => e.type
    ).sort((a, b) => orderMapping[a.code as EOtherArCode] - orderMapping[b.code as EOtherArCode]);
  }, [tripDetail.arItems?.available, tripDetail.arItems?.purchased, tripDetail.journeys, othersType]);

  const SelectorMapping: { [K in EOtherArCode]: (code: EOtherArCode, price?: string | JSX.Element) => JSX.Element } =
    useMemo(
      () => ({
        [EOtherArCode.SPORT_EQUIPMENT]: (code: EOtherArCode, price?: string | JSX.Element) => (
          <SportSection
            code={code}
            price={price}
            passengers={passengers}
            tripDetail={tripDetail}
            tripType={tripType}
            onSportConfirmed={onSportConfirmed}
            onSportSelected={onSportSelected}
            selectedSportArItem={selectedSportArItem}
            onOpenSportDrawer={onOpenSportDrawer}
            onClose={onSportClose}
            sportConfirmed={sportConfirmed}
          />
        ),
        [EOtherArCode.U_FIRST]: (code: EOtherArCode, price?: string | JSX.Element) => (
          <UFirstSection
            code={code}
            price={price}
            passengers={passengers}
            tripDetail={tripDetail}
            tripType={tripType}
            selectedUFirstArItem={selectedUFirstArItem}
            onUFirstConfirmed={onUFirstConfirmed}
            onUFirstSelected={onUFirstSelected}
            onOpenUFirstDrawer={onOpenUFirstDrawer}
            onClose={onUFirstCardClose}
            uFirstConfirmed={uFirstConfirmed}
          />
        ),
        [EOtherArCode.SIM_CARD]: (code: EOtherArCode, price?: string | JSX.Element) => (
          <SimCardSection
            tripDetail={tripDetail}
            passengers={passengers}
            code={code}
            price={price}
            selectedSimCardArItem={selectedSimCardArItem}
            onSimCardConfirmed={onSimCardConfirmed}
            onSimCardSelected={onSimCardSelected}
            onOpenSimCardDrawer={onOpenSimCardDrawer}
            onClose={onSimCardClose}
            simConfirmed={simConfirmed}
          />
        ),
        [EOtherArCode.SIM_CARD_B]: (code: EOtherArCode, price?: string | JSX.Element) => (
          <SimCardSection
            tripDetail={tripDetail}
            passengers={passengers}
            code={code}
            price={price}
            selectedSimCardArItem={selectedSimCardArItem}
            onSimCardConfirmed={onSimCardConfirmed}
            onSimCardSelected={onSimCardSelected}
            onOpenSimCardDrawer={onOpenSimCardDrawer}
            onClose={onSimCardClose}
            simConfirmed={simConfirmed}
          />
        ),
      }),
      [
        passengers,
        tripDetail,
        tripType,
        onSportConfirmed,
        onSportSelected,
        selectedSportArItem,
        onOpenSportDrawer,
        onSportClose,
        selectedUFirstArItem,
        onUFirstConfirmed,
        onUFirstSelected,
        onOpenUFirstDrawer,
        onUFirstCardClose,
        selectedSimCardArItem,
        onSimCardConfirmed,
        onSimCardSelected,
        onOpenSimCardDrawer,
        onSimCardClose,
        simConfirmed,
        sportConfirmed,
        uFirstConfirmed,
      ]
    );
  const { t } = useTransContent();
  return (
    <>
      <Font
        sx={{
          my: ESpacing._sm,
          fontSize: ["22px", "22px", "26px"],
        }}
        variant={EFont.h2}
        fontWeight='bolder'
      >
        {t("web.flightBooking.addExtras.title2")}
      </Font>
      <Grid
        container
        spacing={ESpacing._sm}
        sx={{
          alignItems: "stretch",
        }}
      >
        {otherArItem.map((e) => (
          <Grid key={e.type} item xs={12} md={4}>
            {SelectorMapping[e.code as EOtherArCode](e.code as EOtherArCode, P(e.price, undefined, false, 0, true))}
          </Grid>
        ))}
      </Grid>
    </>
  );
}
