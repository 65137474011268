import { IBEPassengerDetail, Journey, SearchingDetail, TripDetail } from "@hkexpressairwayslimited/ui";
import { createAction } from "@reduxjs/toolkit";
import { PassengerKey } from "[lang]/flight-booking/detail/definition";
import { InitPaymentSaga, LongPollingFormAction, PaymentMetaData } from "lib/features/flight-book/payment/definition";
import { OnBaggageSelectedData, OnMealSelectedData } from "ui/features/flight-booking/add-extras";
import {
  OnSimCardSelectedData,
  OnSportSelectedData,
  OnUFirstSelectedData,
} from "ui/features/flight-booking/add-extras/OthersSection";
import { AddExtrasPageMetaData, SelectedArItems } from "./reducers";

const namespace = "FLIGHT_BOOKING";

export const STORE_FLIGHT_SEARCHING = `${namespace}/STORE_FLIGHT_SEARCHING`;
export const storeFlightSearching = createAction<SearchingDetail>(STORE_FLIGHT_SEARCHING);

export const STORE_TRIP_DETAIL = `${namespace}/STORE_FLIGHT_DETAIL`;
export const storeTripDetail = createAction<TripDetail>(STORE_TRIP_DETAIL);

export const STORE_PASSENGER_DETAIL = `${namespace}/STORE_PASSENGER_FROM_DATA`;
export const storePassengerDetail = createAction<IBEPassengerDetail>(STORE_PASSENGER_DETAIL);

export const UPDATE_STEPPER_INDEX = `${namespace}/UPDATE_STEPPER_INDEX`;
export const updateStepperActive = createAction<number>(UPDATE_STEPPER_INDEX);

export const UPDATE_JOURNEY = `${namespace}/UPDATE_JOURNEY`;
export const updateJourney = createAction<Journey[]>(UPDATE_JOURNEY);

// export const STORE_FLIGHT_SELECT_FLIGHT_DETAIL = `${namespace}/STORE_FLIGHT_SELECT_FLIGHT_DETAIL`;
// export const storeFlightSelectFlightDetail = createAction<{ index: number; journey: Journey }>(
//   STORE_FLIGHT_SELECT_FLIGHT_DETAIL
// );

export const REMOVE_TRIP_DETAIL = `${namespace}/REMOVE_TRIP_DETAIL`;
export const removeTripDetail = createAction<number>(REMOVE_TRIP_DETAIL);

export const PUSH_JOURNEY = `${namespace}/PUSH_JOURNEY`;
export const pushJourney = createAction<Journey>(PUSH_JOURNEY);

export const RESET_STATE = `${namespace}/RESET_STATE`;
export const resetState = createAction(RESET_STATE);

export const UPDATE_BAGGAGE = `${namespace}/UPDATE_BAGGAGE`;
export const updateBaggage = createAction<OnBaggageSelectedData>(UPDATE_BAGGAGE);

export const CONFIRM_SELECTED_BAGGAGE = `${namespace}/CONFIRM_SELECTED_BAGGAGE`;
export const confirmSelectedBaggage = createAction<SelectedArItems[]>(CONFIRM_SELECTED_BAGGAGE);

export const UPDATE_U_FIRST = `${namespace}/UPDATE_U_FIRST`;
export const updateUFirst = createAction<OnUFirstSelectedData>(UPDATE_U_FIRST);
export const CONFIRM_U_FIRST = `${namespace}/CONFIRM_U_FIRST`;
export const confirmUFirst = createAction<SelectedArItems[]>(CONFIRM_U_FIRST);

export const UPDATE_SIM_CARD = `${namespace}/UPDATE_SIM_CARD`;
export const updateSIMCard = createAction<OnSimCardSelectedData>(UPDATE_SIM_CARD);
export const CONFIRM_SIM_CARD = `${namespace}/CONFIRM_SIM_CARD`;
export const confirmSIMCard = createAction<SelectedArItems[]>(CONFIRM_SIM_CARD);

export const UPDATE_SPORT = `${namespace}/UPDATE_SPORT`;
export const updateSport = createAction<OnSportSelectedData>(UPDATE_SPORT);
export const CONFIRM_SPORT = `${namespace}/CONFIRM_SPORT`;
export const confirmSport = createAction<SelectedArItems[]>(CONFIRM_SPORT);

export const UPDATE_ADD_EXTRAS_PAGE_META_DATA = `${namespace}/UPDATE_ADD_EXTRAS_PAGE_META_DATA`;
export const updateAddExtrasPageMetaData = createAction<Partial<AddExtrasPageMetaData>>(
  UPDATE_ADD_EXTRAS_PAGE_META_DATA
);

export const UPDATE_PAX_MEAL = `${namespace}/UPDATE_PAX_MEAL`;
export const updatePaxMeal = createAction<OnMealSelectedData>(UPDATE_PAX_MEAL);

export const CONFIRM_PAX_MEAL = `${namespace}/CONFIRM_PAX_MEAL`;
export const confirmPaxMeal = createAction<SelectedArItems[] | undefined>(CONFIRM_PAX_MEAL);

export const ADD_SEGMENT_SELECTED_SEAT = `${namespace}/ADD_SEGMENT_SELECTED_SEAT`;
export const addSegmentSelectedSeat = createAction<{
  segmentKey: string;
  paxId: string;
  seatDesignator: string;
  unitKey: string;
  seatPrice: number;
}>(ADD_SEGMENT_SELECTED_SEAT);

export const REMOVE_SEGMENT_SELECTED_SEAT = `${namespace}/REMOVE_SEGMENT_SELECTED_SEAT`;
export const removeSegmentSelectedSeat = createAction<{ segmentKey: string; paxId: string }>(
  REMOVE_SEGMENT_SELECTED_SEAT
);
export const IBE_NSK_TOKEN_EXPIRE_NOTIFY = `${namespace}/IBE_NSK_TOKEN_EXPIRE_NOTIFY`;
export const ibeNskTokenExpireNotify = createAction<boolean>(IBE_NSK_TOKEN_EXPIRE_NOTIFY);

export const STORE_PASSENGER_KEY = `${namespace}/STORE_PASSENGER_KEY`;
export const storePassengerKey = createAction<PassengerKey[]>(STORE_PASSENGER_KEY);

export const INIT_PAYMENT_SAGA = `${namespace}/INIT_PAYMENT_SAGA`;
export const initPaymentSaga = createAction<Partial<InitPaymentSaga>>(INIT_PAYMENT_SAGA);

export const CREATE_FORM_ACTION = `${namespace}/CREATE_FORM_ACTION`;
export const createFormAction = createAction<LongPollingFormAction | undefined>(CREATE_FORM_ACTION);

export const STORE_PAYMENT_META_DATA = `${namespace}/STORE_PAYMENT_META_DATA`;
export const storePaymentMetaData = createAction<PaymentMetaData>(STORE_PAYMENT_META_DATA);
