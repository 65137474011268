import {
  Card,
  CardContent,
  EPaxType,
  ESpacing,
  Font,
  IBEPax,
  Journey,
  TickCircleIcon,
} from "@hkexpressairwayslimited/ui";
import { Stack, SvgIcon } from "@mui/material";
import { useTransContent } from "modules/common/trans-content/transContent";
import { useCallback, useMemo } from "react";
import { EArItemType, EBaggageArCode, paxTypeIconMapping } from "..";

type JourneyBaggageSummaryProps = {
  journey: Journey;
  passengers: IBEPax[];
};
export function JourneyBaggageSummary({ journey, passengers }: JourneyBaggageSummaryProps) {
  const includedArItem = useMemo(() => {
    const IncludedBaggageBundle = journey.fare.bundle_offers[0].bundles?.find(
      (e) => e.ssr_code === "PC20" || e.ssr_code === "PC32"
    );
    return IncludedBaggageBundle;
  }, [journey]);

  const formatBaggageSummary = useCallback(
    (paxId: string) => {
      const baggageSummary: Partial<Record<EBaggageArCode, number>> = {};
      if (includedArItem?.ssr_code) {
        baggageSummary[`${includedArItem.ssr_code}.included` as EBaggageArCode] = 1;
      }
      journey.arItems.selected.forEach((e) => {
        if (e.type === EArItemType.BAGGAGE) {
          e.byPax?.forEach((m) => {
            if (m.paxId === paxId && m.amount > 0) {
              baggageSummary[e.code as EBaggageArCode] = m.amount;
            }
          });
        }
      });
      journey.arItems.purchased.forEach((e) => {
        if (e.type === EArItemType.BAGGAGE) {
          e.byPax?.forEach((m) => {
            if (m.paxId === paxId && m.amount > 0 && baggageSummary[e.code as EBaggageArCode]) {
              baggageSummary[e.code as EBaggageArCode] = (baggageSummary[e.code as EBaggageArCode] ?? 0) + m.amount;
            } else if (m.paxId === paxId && m.amount > 0) {
              baggageSummary[e.code as EBaggageArCode] = m.amount;
            }
          });
        }
      });
      return baggageSummary;
    },
    [journey, includedArItem]
  );
  const { t } = useTransContent();
  return (
    <Card bodC='active' withBGC='tertiary'>
      <CardContent>
        <Stack direction='row' spacing={ESpacing._3xs}>
          <SvgIcon
            sx={{
              color: "purple.default",
            }}
          >
            <TickCircleIcon />
          </SvgIcon>
          <Stack spacing={ESpacing._2xs} width='100%'>
            <Font fontWeight='fontWeightBold' color='purple.default'>
              {t("web.addExtras.baggage.checkBaggageDetail")}
            </Font>
            <Stack justifyContent='space-between' spacing={ESpacing._2xs}>
              {passengers.map(
                (e) =>
                  e.paxType !== EPaxType.Infant && (
                    <PassengerBaggageSummary key={e.id} pax={e} detail={formatBaggageSummary(e.id)} />
                  )
              )}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

function PassengerBaggageSummary({ pax, detail }: { pax: IBEPax; detail: Partial<Record<EBaggageArCode, number>> }) {
  const detailsKey = Object.keys(detail);
  const { t } = useTransContent();
  return (
    <Stack sx={{ flexDirection: ["column", "row"] }} justifyContent='space-between'>
      <Stack direction='row' spacing={ESpacing._3xs}>
        <SvgIcon>{paxTypeIconMapping[pax.paxType as EPaxType]}</SvgIcon>
        <Font fontWeight='fontWeightBold'>{`${pax.courtesy} ${pax.surname} ${pax.givenName}`}</Font>
      </Stack>
      <Stack>
        {detailsKey.length ? (
          detailsKey.map((m) => {
            const split = m.split(".");
            if (split.length > 1) {
              return (
                <Stack key={m} direction='row' justifyContent='space-between'>
                  <Font width={"128px"} textAlign={"end"} whiteSpace={"none"}>
                    {t(`${split[0]}.title.short`)}
                  </Font>
                  <Font width={ESpacing._7xl} textAlign={"end"}>
                    {t(`web.addExtras.baggageSection`)}
                  </Font>
                </Stack>
              );
            } else
              return (
                <Stack key={m} direction='row' justifyContent='space-between'>
                  <Font width={"128px"} textAlign={"end"} whiteSpace={"none"}>
                    +{t(`${m}.title.short`)}
                  </Font>
                  <Font width={ESpacing._7xl} textAlign={"end"}>
                    X{detail[m as EBaggageArCode]}
                  </Font>
                </Stack>
              );
          })
        ) : (
          <Stack direction='row' justifyContent='space-between'>
            <Font width={"128px"} textAlign={"end"} whiteSpace={"none"}>
              {t("AC0.title.short")}
            </Font>
            <Font width={ESpacing._7xl} textAlign={"end"}>
              X1
            </Font>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
