import { ECourtesy, EPaxType, ETripType } from "@hkexpressairwayslimited/ui";
import { envConfig } from "env";

export const PassengerMapping = {
  Adult: EPaxType.Adult,
  Child: EPaxType.Child,
  Infant: EPaxType.Infant,
};

export const TripTypeMapping = {
  oneWay: ETripType.oneWay,
  roundTrip: ETripType.roundTrip,
  multiCity: ETripType.multiCity,
};

export const courtesyMapping: { [key: string]: { label: string; value: string }[] } = {
  [EPaxType.Adult]: [
    { label: ECourtesy.Mr, value: ECourtesy.Mr },
    { label: ECourtesy.Mrs, value: ECourtesy.Mrs },
    { label: ECourtesy.Ms, value: ECourtesy.Ms },
  ],
  [EPaxType.Child]: [
    { label: ECourtesy.Master, value: ECourtesy.Master },
    { label: ECourtesy.Miss, value: ECourtesy.Miss },
  ],
  [EPaxType.Infant]: [
    { label: ECourtesy.Master, value: ECourtesy.Master },
    { label: ECourtesy.Miss, value: ECourtesy.Miss },
  ],
};

export const PAYMENT_SUCCESS_URL = `${envConfig.bookingHost}/payment/success`;
export const PAYMENT_FAIL_URL = `${envConfig.bookingHost}/payment/fail`;
