import { getCookieDomain } from "@hkexpressairwayslimited/ui";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { CurrencyDetail, CurrencyRate } from "modules/global/currency/d";
import { RootState } from "store";

const initialState: CurrencyDetail = {
  selectedCurrency: "",
  bookingCurrency: "",
  rate: [],
  asiaMiles: false,
  currentRate: 1,
  roundingFactor: 0,
};

export const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    storeCurrencyDetail: (state, action: PayloadAction<Partial<CurrencyDetail & { trace_id: string }>>) => {
      let { currentRate, roundingFactor } = state;
      const { selectedCurrency } = action.payload;
      if (selectedCurrency !== undefined) {
        Cookies.set("currency", selectedCurrency, {
          domain: getCookieDomain(),
          path: "/",
          expires: 365,
        });
        const { exchange_rate = 1, rounding_factor = 1 } =
          state.rate.find((e) => e.to_currency_code === selectedCurrency) ?? {};
        currentRate = exchange_rate;
        roundingFactor = rounding_factor;
      }
      Object.assign(state, action.payload, { currentRate, roundingFactor });
    },
    storeSelectedCurrency: (state, action: PayloadAction<string>) => {
      let { currentRate, roundingFactor } = state;
      const selectedCurrency = action.payload;
      if (selectedCurrency !== undefined) {
        Cookies.set("currency", selectedCurrency, {
          domain: getCookieDomain(),
          path: "/",
          expires: 365,
        });
        const { exchange_rate = 1, rounding_factor = 1 } =
          state.rate.find((e) => e.to_currency_code === selectedCurrency) ?? {};
        currentRate = exchange_rate;
        roundingFactor = rounding_factor;
      }
      Object.assign(state, { selectedCurrency, currentRate, roundingFactor });
    },
    storeBookingCurrency: (state, action: PayloadAction<string>) => {
      state.bookingCurrency = action.payload;
    },
    storeCurrencyRate: (state, action: PayloadAction<CurrencyRate[]>) => {
      let { currentRate, roundingFactor } = state;
      const { selectedCurrency } = state;
      if (selectedCurrency !== undefined) {
        const { exchange_rate = 1, rounding_factor = 1 } =
          action.payload.find((e) => e.to_currency_code === selectedCurrency) ?? {};
        currentRate = exchange_rate;
        roundingFactor = rounding_factor;
      }
      Object.assign(state, { rate: action.payload, currentRate, roundingFactor });
    },
  },
  selectors: {
    selectCurrency: (state): string => state.selectedCurrency,
    selectCurrencyRate: (state): CurrencyRate[] => state.rate,
    selectBookingCurrency: (state): string => state.bookingCurrency,
    selectCurrencyDetail: (state): CurrencyDetail => state,
  },
});

export const { storeCurrencyDetail, storeSelectedCurrency, storeBookingCurrency, storeCurrencyRate } =
  currencySlice.actions;
export default currencySlice.reducer;
export const { selectCurrency, selectCurrencyRate, selectBookingCurrency, selectCurrencyDetail } =
  currencySlice.getSelectors((state: RootState) => {
    return state.session.currency;
  });
