"use client";
import { TripDetail } from "@hkexpressairwayslimited/ui";
import { endpoints } from "api";
import { getLangLocale } from "modules/common/common/utils/i18n-utils";
import { EffectCallback, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectSalesPortGrouping } from "store/sessionStorage/slices/flightRouteMapping";

export const useEffectOnce = (effect: EffectCallback) => {
  const destroyFunc = useRef<any>();
  const effectCalled = useRef(false);
  const renderAfterCalled = useRef(false);
  const [val, setVal] = useState(0);

  if (effectCalled.current) {
    renderAfterCalled.current = true;
  }

  useEffect(() => {
    // only execute the effect first time around
    if (!effectCalled.current) {
      destroyFunc.current = effect();
      effectCalled.current = true;
    }

    // this forces one render after the effect is run
    setVal((val) => val + 1);

    return () => {
      // if the comp didn't render since the useEffect was called,
      // we know it's the dummy React cycle
      if (!renderAfterCalled.current) {
        return;
      }
      if (destroyFunc.current) {
        destroyFunc.current();
      }
    };
  }, []);
};

export enum APP_CODE {
  AM_PORTAL = "IBAM",
  B2B = "B2B",
  IBE = "IBE",
}
export function useFlightRoute() {
  const airportGroups = useSelector(selectSalesPortGrouping);
  const chinaAirports = useMemo(() => {
    return airportGroups.find((e) => e.market === "CN")?.ports;
  }, [airportGroups]);
  return useCallback(
    (trip?: TripDetail): boolean | undefined => {
      if (!trip) return false;
      for (const journey of trip.journeys) {
        for (const segment of journey.segments) {
          if (
            chinaAirports?.some((e) => e.airport_code === segment.origin) ||
            chinaAirports?.some((e) => e.airport_code === segment.destination)
          ) {
            return true;
          }
        }
      }
      return false;
    },
    [chinaAirports]
  );
}

export function getTemplateLanguage() {
  const locale = getLangLocale().split("-")[0].toUpperCase();
  return locale;
}

export function toFixedNumber(num: number, fractionDigits: number = 2): number {
  return num.toString().includes(".") ? parseFloat(num.toFixed(fractionDigits)) : num;
}

export function isEmptyValue(o: any, emptyValue = ["", undefined, null]): boolean {
  if (typeof o === "object") {
    const keys = Object.keys(o);
    if (keys.length) {
      return !keys.find((e) => !emptyValue.includes((o as [])[e as any]));
    }
    return false;
  }
  return false;
}
export function isFromHKEDomain(str: string) {
  try {
    const url = new URL(str);
    const paymentHost = new URL(endpoints.endpointPaymentHost).hostname;
    return url.hostname === paymentHost;
  } catch (e) {
    return false;
  }
}
