import { clearAuthTokens } from "@hkexpressairwayslimited/ui";
import {
  BackendResponse,
  addQueryParam,
  getAccessTokenHeader,
  getApi,
  memberAuthEndPoints,
  postApi,
  postMessageToMobile,
  windowOpenNewTab,
} from "api";
import { envConfig } from "env";
import { I18nInstance } from "modules/common/common/utils/i18n-utils";
import store from "store";
import { setCxProfile, setMlcTokenProfile } from "../member/actions";
import { fetchTokenSuccess } from "./actions/authAction";
import { clearFallback, clearMlcAccessToken, getAccessToken, getRefreshToken, setFallback } from "./utils/authUtil";

export enum IdentityProvider {
  MLC = "MLC",
  APPLE = "APPLE",
  BIND = "BIND",
}

export async function requestLogout(): Promise<BackendResponse> {
  try {
    clearCognito();
    return await postApi(`${memberAuthEndPoints.logout}`, undefined, undefined, undefined, true);
  } catch (err) {
    return;
  }
}

export async function signInCx(callbackUrl: string = "/", newWindow: boolean = false): Promise<BackendResponse> {
  clearAuthTokens();
  return loginCx(callbackUrl, newWindow);
}
function loginCx(callbackUrl: string, newWindow: boolean) {
  const url = `${envConfig.apiDomain}/auth/v1/oauth/method/mlc?lang=${I18nInstance.getI18nInstance().language}`;
  setIdp(IdentityProvider.MLC);
  return signInByFollowRedirectionAndSetCallbackUrl(url, callbackUrl, newWindow);
}

export async function bindCx(callbackUrl: string = "/member/link-cx"): Promise<BackendResponse> {
  clearCognito();
  const url = `${envConfig.apiDomain}/auth/v1/oauth/method/mlc/bind?lang=${I18nInstance.getI18nInstance().language}`;
  setIdp(IdentityProvider.BIND);
  return signInByFollowRedirectionAndSetCallbackUrl(url, callbackUrl, false);
}
export async function signInApple(callbackUrl: string = "/"): Promise<BackendResponse> {
  clearAuthTokens();
  const url = `${envConfig.apiDomain}/auth/v1/oauth/method/apple?lang=${I18nInstance.getI18nInstance().language}`;
  setIdp(IdentityProvider.APPLE);
  return signInByFollowRedirectionAndSetCallbackUrl(url, callbackUrl, false);
}
async function signInByFollowRedirectionAndSetCallbackUrl(url: string, callbackUrl: string, newWindow: boolean) {
  if (callbackUrl) {
    setFallback(callbackUrl);
  } else {
    clearFallback();
  }
  const urlParams = new URLSearchParams(window.location.search);
  const device = urlParams.get("device") || "web";
  const newUrl = addQueryParam(url, "device", device);
  const accessTokenHeader = getAccessTokenHeader();
  const response = await getApi(newUrl, accessTokenHeader, undefined, {
    redirect: "manual",
    credentials: true,
  });
  if (response.redirect_url) {
    if (newWindow) {
      windowOpenNewTab(response.redirect_url);
    } else {
      window.location.href = response.redirect_url;
    }
  } else {
    throw Error("failure");
  }
}

export async function unlinkIdp(idp: IdentityProvider): Promise<BackendResponse> {
  try {
    const response = await postApi(
      `${memberAuthEndPoints.unlinkIdp}`,
      { idp, refresh_token: getRefreshToken() },
      undefined,
      undefined,
      true
    );
    if (response.metadata.status_code === 200) {
      const { access_token, refresh_token, id_token } = response;
      if (id_token) {
        const accessToken = access_token || getAccessToken();
        const refreshToken = refresh_token || getRefreshToken();
        const mlcAccessToken = "";
        store.dispatch(
          fetchTokenSuccess({
            accessToken,
            refreshToken,
            idToken: id_token,
            mlcAccessToken,
          })
        );
        if (window.ReactNativeWebView) {
          postMessageToMobile({
            accessToken,
            refreshToken,
            idToken: id_token,
            mlcAccessToken,
          });
        }
      }
    }
  } catch (err) {
    console.error(err);
  }
}

export async function unlinkCx() {
  clearCognito();
  try {
    await unlinkIdp(IdentityProvider.MLC);
  } catch (err) {
    console.error(err);
  }
  clearMlcAccessToken();
  store.dispatch(setMlcTokenProfile(undefined));
  store.dispatch(setCxProfile(undefined));
}

export async function reAuthCx(callbackUrl: string = "/", newWindow: boolean = false) {
  clearCognito();
  setTimeout(() => {
    loginCx(callbackUrl, newWindow);
  }, 500);
}

function clearCognito() {
  try {
    const cognitoLogoutUrl = `${envConfig.cognitoDomain}/logout?client_id=${envConfig.cognitoClientId}&logout_uri=${envConfig.bookingHost}/member/fallback`;
    // const cognitoLogoutUrl = `${envConfig.apiDomain}/auth/v1/oauth/logout`;
    setFallback("");
    windowOpenNewTab(cognitoLogoutUrl);
    // const accessTokenHeader = getAccessTokenHeader();
    // await getApi(cognitoLogoutUrl, accessTokenHeader, undefined, {
    //   redirect: "manual",
    // }, true);
  } catch (err) {
    console.error(err);
  }
}

export async function unlinkApple() {
  await unlinkIdp(IdentityProvider.APPLE);
}

function setIdp(idp: IdentityProvider) {
  return localStorage.setItem("hke_idp", idp);
}
export function getIdp() {
  return localStorage.getItem("hke_idp");
}
