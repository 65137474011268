import { createAction } from "@reduxjs/toolkit";
import { InitB2BMMBPaymentSaga } from "lib/features/b2b/payment/definition";
import { LongPollingFormAction } from "lib/features/flight-book/payment/definition";
import {
  InitMMBChangeFlightPaymentSaga,
  InitMMBPaymentSaga,
} from "lib/features/manage-my-booking/my-trips/payment/definition";

const namespace = "B2B_MANAGE_MY_BOOKING";

export const INIT_B2B_MMB_PAYMENT_SAGA = `${namespace}/INIT_B2B_MMB_PAYMENT_SAGA`;
export const initB2BMMBPaymentSaga = createAction<Partial<InitB2BMMBPaymentSaga>>(INIT_B2B_MMB_PAYMENT_SAGA);

export const CREATE_B2B_MMB_FORM_ACTION = `${namespace}/CREATE_FORM_ACTION`;
export const createB2BMMBFormAction = createAction<LongPollingFormAction | undefined>(CREATE_B2B_MMB_FORM_ACTION);

export const INIT_B2B_MMB_CHANGE_FLIGHT_PAYMENT_SAGA = `${namespace}/INIT_B2B_MMB_CHANGE_FLIGHT_PAYMENT_SAGA`;
export const initB2BMMBChangeFlightPaymentSaga = createAction<Partial<InitMMBChangeFlightPaymentSaga>>(
  INIT_B2B_MMB_CHANGE_FLIGHT_PAYMENT_SAGA
);

export const INIT_B2B_MMB_UNSETTLE_PAYMENT_SAGA = `${namespace}/INIT_B2B_MMB_UNSETTLE_PAYMENT_SAGA`;
export const initB2BMMBUnsettlePaymentSaga = createAction<InitMMBPaymentSaga>(INIT_B2B_MMB_UNSETTLE_PAYMENT_SAGA);
