import { ArItem, ArItems, Journey, TripDetail } from "@hkexpressairwayslimited/ui";
import { MMBJourney, ManageMyBookingTripDetail } from "lib/features/manage-my-booking/my-trips/definition";
import { useMemo } from "react";
import { EArItemType } from "ui/features/flight-booking/add-extras";
import { calcAllPickedArItems } from "./calcAllPickedArItems";
export function useJourneyCost(str?: EArItemType, tripArItems?: ArItems[]) {
  // const storedJourneyArItem = getStoredJourneyArItems(useSelector(selectTripJourneys));
  return useMemo(() => {
    return calcAllPickedArItems(tripArItems ? tripArItems : [], str ? (e) => e.type === str : undefined, true);
  }, [tripArItems, str]);
}

export function getStoredJourneyArItems(journeys?: Journey[] | MMBJourney[]) {
  return journeys?.reduce<ArItems[]>((a, c) => [...a, c.arItems], []) ?? undefined;
}

export function getJourneyPurchasedExtrasCost(journey: Journey | MMBJourney) {
  const journeyArCost = journey.arItems.purchased.reduce((a, c) => (c.price ? a + c.price : a), 0);
  const segmentArCost = journey.segments.reduce(
    (a, c) => c.arItems.purchased.reduce((a1, c1) => (c1.price ? a1 + c1.price : a1), 0) + a,
    0
  );

  return journeyArCost + segmentArCost;
}

export function getJourneySelectedExtrasCost(
  trip: ManageMyBookingTripDetail | TripDetail | undefined,
  journey: Journey | MMBJourney | undefined,
  index: number,
  withSeatCost: boolean
) {
  const journeyArCost = journey?.arItems.selected.reduce((a, c) => {
    return (
      a +
      (journey.arItems.available.find((e) => e.code === c.code)?.price ?? 0) *
        (c.byPax ? c.byPax?.reduce((a2, c2) => a2 + c2.amount, 0) : 0)
    );
  }, 0);
  const segmentArCost = journey?.segments.reduce(
    (a, c) =>
      a +
      c.arItems.selected.reduce((a1, c1) => {
        return (
          a1 +
          (c1.price && withSeatCost
            ? c1.price
            : (c.arItems.available.find((e) => e.code === c1.code)?.price ?? 0) *
              (c1.byPax ? c1.byPax?.reduce((a2, c2) => a2 + c2.amount, 0) : 0))
        );
      }, 0),
    0
  );

  const selectedSimCard = trip?.arItems.selected.find((e) => e.type === EArItemType.SIM_CARD);
  const availableSimCard = trip?.arItems.available.find((e) => e.type === EArItemType.SIM_CARD);

  const simCardCost =
    index === 0
      ? (availableSimCard?.price ?? 0) *
        (selectedSimCard && selectedSimCard.byPax ? selectedSimCard.byPax.reduce((a, c) => c.amount + a, 0) : 0)
      : 0;

  return (journeyArCost ?? 0) + (segmentArCost ?? 0) + simCardCost;
}

export function getStoredSelectedJourneyArItems(journeys?: Journey[] | MMBJourney[]) {
  return journeys?.reduce((a: ArItem[], c: Journey | MMBJourney) => a.concat([...c.arItems.selected]), []) ?? undefined;
}
