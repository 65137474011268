"use client";
import { ECourtesy, IContactMobile, MlcTokenProfile } from "@hkexpressairwayslimited/ui";
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { setCxProfile, setGeneralProfile, setMilesInfo, setMlcTokenProfile, setTokenProfile } from "./actions";
import { CxProfile, GeneralProfile, MilesInfo, TokenProfile } from "./types";

// import { storeFlightSearching, storeTripDetail, storePassengerDetail, updateStepperActive } from "./actions";
import { storePassengerDetail } from "./actions";

export enum CompanionStatus {
  Edit = "Edit",
  Add = "Add",
  Disabled = "Disabled",
}
export interface CompanionDetails {
  contactsCourtesy?: ECourtesy;
  contactsSurname?: string;
  contactsGivenName?: string;
  contactsDob?: string;
  contactsEmail?: string;
  status?: CompanionStatus;
  id?: number;
}
export interface MemberDetails {
  contactsCourtesy: ECourtesy;
  contactsSurname: string;
  contactsGivenName: string;
  contactsMobile: IContactMobile;
  contactsEmail: string;
  contactsDob: string;
  // sendSMSitinerary: boolean;
  eNews: boolean;
  receiveMarketingAndPromotional: boolean;
  // rememberThisDetails: boolean;
  // passengers: Passenger[];
  companions?: CompanionDetails[];

  docType?: string;
  docIssuingCountry?: string;
  docNationality?: string;
  docNumber?: string;
  docExpirationDate?: string;
  docDefault?: boolean;

  contactEmailVerified?: boolean;
  contactPhoneNumberVerified?: boolean;
  freezedAttributes?: string[];
}
export interface MemberState {
  isLogin: boolean;
  // searchingDetail?: SearchingDetail;
  memberDetails?: MemberDetails;
  // tripDetail?: TripDetail;
  // stepperIndex: number;
  generalProfile?: GeneralProfile;
  tokenProfile?: TokenProfile;
  mlcTokenProfile?: MlcTokenProfile;
  cxProfile?: CxProfile;
  milesInfo?: MilesInfo;
}

const initialState: MemberState = {
  isLogin: false,
  // stepperIndex: 0,
  // searchingDetail: undefined,
  // tripDetail: undefined,
  memberDetails: undefined,
  cxProfile: undefined,
  generalProfile: undefined,
  tokenProfile: undefined,
  mlcTokenProfile: undefined,
  milesInfo: undefined,
};

export const MemberReducer = createReducer(initialState, (builder) => {
  builder
    .addMatcher(isAnyOf(setCxProfile), (state, action) => {
      state.cxProfile = action.payload;
    })
    .addMatcher(isAnyOf(setTokenProfile), (state, action) => {
      state.isLogin = Boolean(action.payload);
      state.tokenProfile = action.payload;
    })
    .addMatcher(isAnyOf(setMlcTokenProfile), (state, action) => {
      state.mlcTokenProfile = action.payload;
    })
    .addMatcher(isAnyOf(setGeneralProfile), (state, action) => {
      state.generalProfile = action.payload;
    })
    .addMatcher(isAnyOf(setMilesInfo), (state, action) => {
      state.milesInfo = action.payload;
    })
    // .addMatcher(isAnyOf(storeFlightSearching), (state, action) => {
    //   state.searchingDetail = action.payload;
    // })
    // .addMatcher(isAnyOf(storeTripDetail), (state, action) => {
    //   state.tripDetail = action.payload;
    // })
    .addMatcher(isAnyOf(storePassengerDetail), (state, action) => {
      state.memberDetails = action.payload;
    });
  // .addMatcher(isAnyOf(updateStepperActive), (state, action) => {
  //   state.stepperIndex = action.payload;
  // });
});
