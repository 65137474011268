import { unstable_cache } from "next/cache";

// Check if the URL is a valid image URL with supported file extensions for blur image generation
export function isValidBlurImageURL(url: string) {
  try {
    const urlObj = new URL(url, "https://-");

    return urlObj.pathname.match(/.+\.(webp|jpg|jpeg|gif|png|tiff|bmp)$/gi);
  } catch {
    return false;
  }
}

// Fetch blur image data using plaiceholder library to generate blur image for image loading optimization and performance improvement
export async function getBlurData(src: string) {
  if (!isValidBlurImageURL(src)) {
    return null;
  }
  const { promises: fs } = await import("fs");
  const { resolve } = await import("path");
  let buffer: Buffer;
  const path = new URL(src, "https://-");

  console.log("Fetching blur image", src);

  let startTime = performance.now();
  if (path.origin === "https://-") {
    buffer = await fs.readFile(resolve(`./public${path.pathname}`));
  } else {
    buffer = await fetch(path).then(async (res) => Buffer.from(await res.arrayBuffer()));
  }
  console.log("Fetched blur image", src, "in", performance.now() - startTime, "ms");

  startTime = performance.now();
  const { getPlaiceholder } = await import("plaiceholder");
  const data = await getPlaiceholder(buffer, { size: 4 });
  console.log("Generated blur image", src, "in", performance.now() - startTime, "ms");
  return data;
}

// Fetch blur image data for all images in the content to generate blur image list for image loading optimization and performance improvement
// The blur image list will be used to show blur images during image loading
export const getBlurImageList = async (content: Record<string, string>) => {
  // Skip fetching blur image data in development mode
  if (process.env.NODE_ENV === "development") {
    return {};
  }

  const { cpus } = await import("os");
  const numCPUs = cpus().length;
  const tasks = Math.min(5, numCPUs);
  const blurImageList: Record<string, string> = {};
  // Create a map of blur image URL and its corresponding promise function to fetch blur image data
  // This will help to avoid fetching the same blur image data multiple times
  const blurImagePromiseFuncMap: Record<string, typeof getBlurData> = {};
  if (content) {
    for (const [key, value] of Object.entries(content)) {
      if (!blurImagePromiseFuncMap[value]) {
        if (isValidBlurImageURL(value)) {
          blurImagePromiseFuncMap[value] = unstable_cache(getBlurData, ["getBlurData", value], {
            revalidate: 60 * 60,
          });
        } else {
          blurImagePromiseFuncMap[value] = () => Promise.resolve(null);
        }
      }
    }
  }

  const blurImageListEntries: Awaited<
    ReturnType<(typeof blurImagePromiseFuncMap)[keyof typeof blurImagePromiseFuncMap]>
  >[] = [];

  // Fetch blur image data in parallel using Promise.all and CPU count to improve performance
  // and avoid blocking the main thread
  // Fetching blur image data in parallel will also help to avoid blocking the main thread
  for (let i = 0; i < Object.values(blurImagePromiseFuncMap).length; i += tasks) {
    const allPromises = await Promise.all(
      Object.entries(blurImagePromiseFuncMap)
        .slice(i, i + tasks)
        .map(([key, func]) =>
          func(key).catch((err) => {
            console.warn("Error fetching blur image", key, err);
            return null;
          })
        )
    );

    blurImageListEntries.push(...allPromises);
  }

  // Assign blur image data to blur image list
  Object.entries(blurImagePromiseFuncMap).forEach(([key, promise], index) => {
    const blurData = blurImageListEntries[index];

    if (blurData) {
      blurImageList[key] = blurData.base64;
    }
  });

  return blurImageList;
};
