import {
  ArItem,
  ArItems,
  Button,
  ButtonVariant,
  Card,
  CardCollapse,
  CardContent,
  EBorders,
  EFont,
  EPaxType,
  ERadius,
  ESpacing,
  Font,
  IBEPax,
  MealIcon,
  NumberInput,
  Tabs,
} from "@hkexpressairwayslimited/ui";
import { Box, Stack } from "@mui/material";
import { uniqBy } from "lodash";
import { useTransContent } from "modules/common/trans-content/transContent";
import { useCurrency } from "modules/global/currency/useCurrency";
import Image from "next/image";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ArItemsAmount, paxTypeIconMapping } from "..";
import { Drawer } from "../../Drawer";
export type MealDrawerProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmLabel?: string | JSX.Element | JSX.Element[];
  totalCost: string | JSX.Element;
  currentSegmentMealArItem: ArItems | undefined;
  passengers?: IBEPax[];
  onPaxSelectMeal: (paxId: string, mealCode: string, amount: number) => void;
  switcher?: JSX.Element;
  autoClose?: boolean;
};
// type MealNavButtonData = {
//   // selectAmount:number
//   journey_key: string;
// };
type MealSelected = {
  [K: string]: number;
};
type MealPurchased = {
  [K: string]: number;
};
type UserSelectedMeal = ArItemsAmount | undefined;
export function MealDrawer({
  open,
  onClose,
  onConfirm,
  totalCost,
  currentSegmentMealArItem,
  passengers,
  onPaxSelectMeal,
  switcher,
  confirmLabel,
  autoClose = true,
}: MealDrawerProps) {
  const [selectedMeal, setSelectMeal] = useState("");
  const [curExpandPurchasedCode, setCurExpandPurchasedCode] = useState<string[]>([]);
  const [curTab, setCurTab] = useState(0);
  const curPassengerId = passengers?.reduce((a: string[], i) => [...a, i.id], []);
  const { userSelected, mealSelected, mealPurchased, userPurchased, totalPurchased } =
    useMemo(() => {
      if (!currentSegmentMealArItem) return undefined;
      const userSelected: UserSelectedMeal = {};
      const mealSelected: MealSelected = {};
      const mealPurchased: MealPurchased = {};
      const userPurchased: UserSelectedMeal = {};
      currentSegmentMealArItem.selected.forEach((e) => {
        e.byPax?.forEach((n) => {
          if (userSelected[n.paxId]) {
            userSelected[n.paxId][e.code] = n.amount;
          } else {
            userSelected[n.paxId] = { [e.code]: n.amount };
          }
          if (mealSelected[e.code]) {
            mealSelected[e.code] = (mealSelected[e.code] ?? 0) + n.amount;
          } else {
            mealSelected[e.code] = n.amount;
          }
        });
      });
      let totalPurchased = 0;
      currentSegmentMealArItem.purchased.forEach((e) => {
        e.byPax?.forEach((n) => {
          if (curPassengerId?.includes(n.paxId)) {
            totalPurchased = totalPurchased + n.amount;
            if (userPurchased[n.paxId]) {
              userPurchased[n.paxId][e.code] = n.amount;
            } else {
              userPurchased[n.paxId] = { [e.code]: n.amount };
            }
            if (mealPurchased[e.code]) {
              mealPurchased[e.code] = (mealPurchased[e.code] ?? 0) + n.amount;
            } else {
              mealPurchased[e.code] = n.amount;
            }
          }
        });
      });
      return { userSelected, mealSelected, mealPurchased, userPurchased, totalPurchased };
    }, [curPassengerId, currentSegmentMealArItem]) ?? {};
  const mealAdded = useCallback(
    (mealCode: string) => {
      if (mealSelected && mealSelected[mealCode]) {
        return mealSelected[mealCode] ?? 0;
      }
      return 0;
    },
    [mealSelected]
  );
  const mealPurchasedAdded = useCallback(
    (mealCode: string) => {
      if (mealPurchased && mealPurchased[mealCode]) {
        return mealPurchased[mealCode] ?? 0;
      }
      return 0;
    },
    [mealPurchased]
  );
  function handlePaxUpdateMealNum(paxId: string, mealCode: string, amount: number) {
    if (paxId && mealCode && amount > -1) {
      onPaxSelectMeal(paxId, mealCode, amount);
    }
  }
  const { t } = useTransContent();
  const availableArItem = currentSegmentMealArItem ? currentSegmentMealArItem.available : [];
  const purchasedArItem = currentSegmentMealArItem ? currentSegmentMealArItem.purchased : [];
  const allMeal = uniqBy(availableArItem.concat(purchasedArItem), (e) => e.code);
  const preOrderMeal =
    currentSegmentMealArItem &&
    currentSegmentMealArItem.available &&
    currentSegmentMealArItem.available.filter((item) => item.is_pre_order);
  const vegetarianMeal =
    currentSegmentMealArItem &&
    currentSegmentMealArItem.available &&
    currentSegmentMealArItem.available.filter((item) => item.is_vegetarian);
  const resultMeal = curTab === 1 ? preOrderMeal : curTab === 2 ? vegetarianMeal : allMeal;
  const childrenItem =
    resultMeal && resultMeal.length > 0 ? (
      <Stack overflow={"scroll"} maxHeight={"330px"}>
        {resultMeal.map((e) => (
          <MealSelectorItem
            added={mealAdded(e.code) + mealPurchasedAdded(e.code) > 0}
            onClick={(mealCode) => {
              if (curExpandPurchasedCode.includes(mealCode)) {
                setSelectMeal("");
                setCurExpandPurchasedCode(curExpandPurchasedCode.filter((item) => item !== mealCode));
              } else {
                setSelectMeal(selectedMeal !== "" && selectedMeal === mealCode ? "" : mealCode);
                setCurExpandPurchasedCode([]);
              }
            }}
            passengersExpanded={selectedMeal === e.code || curExpandPurchasedCode.includes(e.code)}
            key={e.code}
            arItem={e}
            passengerSelection={
              passengers && (
                <PassengerSection
                  onPaxUpdateMealNum={handlePaxUpdateMealNum}
                  max={e.availability ?? 0}
                  mealCode={e.code}
                  pax={passengers}
                  userSelected={userSelected}
                  userPurchased={userPurchased}
                  mealSelected={mealAdded(e.code)}
                  totalPurchased={totalPurchased ?? 0}
                  limitPerPassenger={e.limit_per_passenger ?? Infinity}
                />
              )
            }
          />
        ))}
      </Stack>
    ) : (
      <Box>{t("web.flightBooking.mealDrawer.noresult")}</Box>
    );
  useEffect(() => {
    setCurTab(0);
    setSelectMeal("");
    setCurExpandPurchasedCode(Object.keys(mealPurchased || {}));
  }, [open]);
  return (
    <Drawer
      title={t("web.addExtras.meal.title")}
      open={open}
      onClose={onClose}
      avatar={<MealIcon />}
      totalCost={totalCost}
      onConfirm={onConfirm}
      autoClose={autoClose}
      confirmLabel={t("web.manageMyBooking.myTrips.addItems.btn")}
      crossIconClose={true}
    >
      <Stack spacing={ESpacing._sm} width={"100%"}>
        <Font>{t("web.addExtras.meal.drawer.preOrder")}</Font>
        {switcher}
        <Tabs
          onChange={(value: number) => {
            setCurTab(value);
          }}
          id='meals-tab'
          tabs={[
            {
              label: t("web.flightBooking.mealDrawer.tab1") as string,
              children: childrenItem,
            },
            {
              label: t("web.flightBooking.mealDrawer.tab2") as string,
              children: childrenItem,
            },
            {
              label: t("web.flightBooking.mealDrawer.tab3") as string,
              children: childrenItem,
            },
          ]}
          scrollable={true}
        />

        <ul>
          <li>
            <Font variant={EFont.p3}>{t("web.addExtras.meal.drawer.content1")}</Font>
          </li>
          <li>
            <Font variant={EFont.p3}>{t("web.addExtras.meal.drawer.content2")}</Font>
          </li>
          <li>
            <Font variant={EFont.p3}>{t("web.addExtras.meal.drawer.content3")}</Font>
          </li>
          <li>
            <Font variant={EFont.p3}>{t("web.addExtras.meal.drawer.content4")}</Font>
          </li>
        </ul>
      </Stack>
    </Drawer>
  );
}

function MealSelectorItem({
  arItem,
  passengerSelection,
  passengersExpanded,
  onClick,
  added,
}: {
  arItem: ArItem;
  passengerSelection?: JSX.Element;
  passengersExpanded: boolean;
  onClick: (mealCode: string) => void;
  added: boolean;
}) {
  const { t } = useTransContent();
  const { P } = useCurrency();
  const addedBackgroundObj = added ? { backgroundColor: "#E2D5E9" } : {};
  return (
    <Card
      borderless
      sx={{
        borderRadius: 0,
        "& > div.ContentWrapper": {
          padding: "16px 0 0 0",
        },
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack direction={["column", "row"]} columnGap={[0, ESpacing._2xs]}>
          {/* If there is no valid mirror path or the path is empty, the mirror path is defaulted, otherwise an invalid path error is reported */}
          <Image
            src={
              t(`${arItem.code}.imageUrl`) === `${arItem.code}.imageUrl`
                ? "/images/image.png"
                : (t(`${arItem.code}.imageUrl`) as string)
            }
            width='120'
            height='120'
            alt={""}
          />
          <Stack
            className='MediaContent'
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            columnGap={ESpacing._sm}
            sx={{ width: "100%" }}
          >
            <Stack spacing={ESpacing._3xs}>
              <Stack direction='row' columnGap={1}>
                <Font>{t(`${arItem.code}.title`)}</Font>
                <Box display={arItem.is_pre_order ? "block" : "none"}>
                  <Image alt='' src={t("web.addExtras.meal.drawer.preOrder.image") as string} width={24} height={24} />
                </Box>
                <Box display={arItem.is_vegetarian ? "block" : "none"}>
                  <Image
                    alt=''
                    src={t("web.addExtras.meal.drawer.vegetarian.image") as string}
                    width={24}
                    height={24}
                  />
                </Box>
              </Stack>
              <Font display='flex' color='purple.default' fontWeight='600'>
                {P(arItem.price, undefined, false, 0, true)}
              </Font>
            </Stack>
            <Button
              style={{ ...addedBackgroundObj }}
              onClick={() => onClick && onClick(arItem.code)}
              variant={ButtonVariant.Secondary}
            >
              {added
                ? t("web.flightBooking.otherSection.button.added")
                : t("web.flightBooking.otherSection.button.add")}
            </Button>
          </Stack>
        </Stack>
        <CardCollapse in={passengersExpanded} timeout='auto' unmountOnExit>
          {passengerSelection}
        </CardCollapse>
      </CardContent>
    </Card>
  );
}

function PassengerSection({
  pax,
  userSelected,
  userPurchased,
  totalPurchased,
  mealSelected,
  mealCode,
  max,
  onPaxUpdateMealNum,
  limitPerPassenger,
}: {
  pax: IBEPax[];
  userSelected: UserSelectedMeal;
  userPurchased: UserSelectedMeal;
  totalPurchased: number;
  mealSelected: number;
  mealCode: string;
  max: number;
  onPaxUpdateMealNum: (paxId: string, mealCode: string, amount: number) => void;
  limitPerPassenger: number;
}) {
  function handlePassengerSelectMealNum(paxId: string, amount: number) {
    onPaxUpdateMealNum && onPaxUpdateMealNum(paxId, mealCode, amount);
  }
  // const addDisable = useCallback((purchased = 0) => {
  //   return max - mealSelected <= 0
  // }, [max, mealSelected]);
  return (
    <Stack
      sx={{
        borderRadius: ERadius.r200,
        overflow: "hidden",
        ["& .MuiStack-root:last-of-type"]: { borderBottom: "none" },
      }}
    >
      {pax.map((e) => {
        const purchased = (userPurchased && userPurchased[e.id] && userPurchased[e.id][mealCode]) ?? 0;
        const selected = userSelected && userSelected[e.id] && userSelected[e.id][mealCode];
        return (
          <PassengerSectionItem
            // addDisable={addDisable(purchased)}
            max={max}
            totalSelected={mealSelected}
            purchased={purchased}
            key={e.id}
            pax={e}
            onPassengerSelected={handlePassengerSelectMealNum}
            value={selected ? selected.toString() : "0"}
            limitPerPassenger={limitPerPassenger}
          />
        );
      })}
    </Stack>
  );
}
function PassengerSectionItem({
  pax,
  value,
  purchased = 0,
  // addDisable,
  max,
  totalSelected,
  onPassengerSelected,
  limitPerPassenger,
}: {
  max: number;
  totalSelected: number;
  pax: IBEPax;
  // addDisable: boolean;
  purchased?: number;
  onPassengerSelected: (paxId: string, amount: number) => void;
  value: string;
  limitPerPassenger: number;
}) {
  const mealValue = (isNaN(Number(value)) ? purchased : Number(value) + purchased).toString();
  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={{ backgroundColor: "neutral.background", padding: ESpacing._s, borderBottom: EBorders.b1 }}
    >
      <Stack direction='row' spacing={ESpacing._3xs}>
        {paxTypeIconMapping[pax.paxType as EPaxType]}
        <Font fontWeight={"600"}>{`${pax.courtesy} ${pax.surname} ${pax.givenName}`}</Font>
      </Stack>
      <Box width={{ width: "124px" }}>
        <NumberInput
          max={totalSelected >= max || Number(mealValue) >= limitPerPassenger ? value : Infinity}
          min={purchased}
          value={mealValue}
          onChange={(e) => {
            const amount = isNaN(Number(e.target.value)) ? 0 : Number(e.target.value);
            onPassengerSelected(pax.id, amount - purchased);
          }}
        />
      </Box>
    </Stack>
  );
}
