import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { LongPollingFormAction } from "lib/features/flight-book/payment/definition";
import { createB2BMMBFormAction } from "./actions";

export interface B2BReducerProps {
  formAction?: LongPollingFormAction;
}

const initialState: B2BReducerProps = {
  formAction: undefined,
};

export const B2BMMBReducer = createReducer(initialState, (builder) => {
  builder.addMatcher(isAnyOf(createB2BMMBFormAction), (state, action) => {
    state.formAction = action.payload;
  });
});
