import { GetCxMemberProfileRes, getCxMemberProfile } from "lib/common/services/memberService";

import { IdTokenPayload, MlcAccessTokenPayload, decodeTokenPayLoad } from "@hkexpressairwayslimited/ui";
import { FETCH_CX_PROFILE, INIT_TOKEN_PROFILE, setCxProfile, setTokenProfile } from "modules/features/member/actions";
import { decodeTokenProfile } from "modules/features/member/service";
import { CxProfile } from "modules/features/member/types";
import { call, put, takeLatest } from "redux-saga/effects";
import { getIdToken, getMlcAccessToken } from "../utils/authUtil";

function mapToMemberProfile(res: GetCxMemberProfileRes): CxProfile {
  return {
    memberNumber: res.member_info.member_number,
    memberName: {
      embossedName: res.member_info.member_name.embossed_name,
      title: res.member_info.member_name.title,
      namingConvention: res.member_info.member_name.naming_convention,
      givenName: res.member_info.member_name.given_name,
      familyName: res.member_info.member_name.family_name,
    },
    totalMilesAvailable: res.member_info.total_miles_available,
    expiryTime: res.member_info.expiry_time,
    tierCode: res.member_info.tier_code,
    mobilePhoneNumber: res.member_info.mobile_phone_number,
    email: res.member_info.email,
  };
}

function* fetchCxProfile(): Generator<any, void, GetCxMemberProfileRes & string> {
  try {
    const mlcAccessToken = yield call(getMlcAccessToken);

    if (!mlcAccessToken) return;

    const mlcAccessTokenPayload = decodeTokenPayLoad<MlcAccessTokenPayload>(mlcAccessToken);

    if (!mlcAccessTokenPayload) return;
    // const mlcProfile = decodeMlcTokenProfile(mlcAccessTokenPayload);
    if (!mlcAccessTokenPayload.sub) return;
    const memberAndMilesInfoRes: GetCxMemberProfileRes = yield call(getCxMemberProfile, {
      member_number: mlcAccessTokenPayload.sub,
    });

    const profile: CxProfile = mapToMemberProfile(memberAndMilesInfoRes);

    yield put(setCxProfile(profile));
  } catch (err) {
    console.error(err);
  }
}

function* initTokenProfile(): Generator<any, void, any> {
  const idToken = getIdToken();
  console.log("idToken", idToken);

  if (!idToken) return;

  const idTokenPayload = decodeTokenPayLoad<IdTokenPayload>(idToken);

  console.log("idTokenPayload", idTokenPayload);

  if (!idTokenPayload) return;
  const tokenProfile = decodeTokenProfile(idTokenPayload);

  console.log("tokenProfile", tokenProfile);
  yield put(setTokenProfile(tokenProfile));
}

function* initMlcTokenProfile(): Generator<any, void, any> {
  // const mlcAccessToken = getMlcAccessToken();
  // console.log("mlcAccessToken", mlcAccessToken);
  // if (!mlcAccessToken) return;
  // const mlcAccessTokenPayload = decodeTokenPayLoad<MlcAccessTokenPayload>(mlcAccessToken);
  // console.log("mlcAccessTokenPayload", mlcAccessTokenPayload);
  // if (!mlcAccessTokenPayload) return;
  // const mlcTokenProfile = decodeMlcTokenProfile(mlcAccessTokenPayload);
  // console.log("mlcTokenProfile", mlcTokenProfile);
  // yield put(setMlcTokenProfile(mlcTokenProfile));
}

export function* memberSaga() {
  yield takeLatest(FETCH_CX_PROFILE, fetchCxProfile);
  yield takeLatest(INIT_TOKEN_PROFILE, initTokenProfile);
  yield takeLatest(INIT_TOKEN_PROFILE, initMlcTokenProfile);
}
