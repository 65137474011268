import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { closeLoadingBackdrop, showLoadingBackdrop } from "../actions/LoadingBackdropAction";

export interface LoadingBackdropState {
  loadingCount: number;
  zIndex?: number | string;
}

const initialState = {
  loadingCount: 0,
  zIndex: 1024,
} as LoadingBackdropState;

export const loadingBackdropReducer = createReducer(initialState, (builder) => {
  builder
    .addMatcher(isAnyOf(showLoadingBackdrop), (state, action) => {
      if (action.payload?.zIndex) {
        state.zIndex = action.payload?.zIndex;
      } else {
        state.loadingCount += 1;
      }
    })
    .addMatcher(isAnyOf(closeLoadingBackdrop), (state, action) => {
      if (action.payload === 0) {
        state.loadingCount = 0;
      } else {
        if (state.loadingCount > 0) {
          state.loadingCount -= 1;
        }
      }
      // state.zIndex = initialState.zIndex;
    });
});
