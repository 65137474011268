import { BigImageBox, Button, EFont, ESpacing, Font, NoBaggageIcon, NoMealIcon } from "@hkexpressairwayslimited/ui";
import { Stack, SvgIcon } from "@mui/material";
import { useTransContent } from "modules/common/trans-content/transContent";
export function InvalidMessagePopUp({
  baggageError,
  mealError,
  onConfirm,
  onCancel,
}: {
  baggageError?: boolean;
  mealError?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) {
  const { t } = useTransContent();
  const popupImage = {
    url: "/images/img.png",
    width: 390,
    height: 238,
    alt: "Popup Image",
  };
  return (
    <Stack>
      <Font variant={EFont.h2} fontWeight='fontWeightBold'>
        {t("web.addExtras.title")}
      </Font>
      <Font fontWeight='fontWeightBold' sx={{ fontSize: "18px", lineHeight: "22px" }}>
        {t("web.addExtras.content1")}
      </Font>
      <Stack justifyContent='center' sx={{ width: "auto", height: "190px" }}>
        {/* <Image src='/images/img.png' width='390' height='238' alt={""} /> */}
        <BigImageBox Image={popupImage} />
      </Stack>
      <Font variant={EFont.p2} sx={{ mt: ESpacing._3xs, lineHeight: "18px" }}>
        {t("web.addExtras.content2")}
      </Font>
      {baggageError && (
        <Stack direction='row' spacing={ESpacing._3xs}>
          <SvgIcon>
            <NoBaggageIcon />
          </SvgIcon>
          <Font fontWeight='fontWeightBold' variant={EFont.p2} mt={ESpacing._3xs}>
            {t("web.addExtras.meal.popup.baggageError")}
          </Font>
        </Stack>
      )}
      {mealError && (
        <Stack direction='row' spacing={ESpacing._3xs}>
          <SvgIcon>
            <NoMealIcon />
          </SvgIcon>
          <Font fontWeight='fontWeightBold' variant={EFont.p2} mt={ESpacing._3xs}>
            {t("web.addExtras.meal.popup.mealError")}
          </Font>
        </Stack>
      )}
      <Stack spacing={ESpacing._2xs}>
        <Button onClick={onCancel} fullWidth>
          <Font variant={EFont.p2}>{t("web.addExtras.meal.popup.button.addExtras")}</Font>
        </Button>
        <Button onClick={onConfirm} fullWidth outlined>
          <Font variant={EFont.p2}>{t("web.addExtras.meal.popup.button.continue")}</Font>
        </Button>
      </Stack>
    </Stack>
  );
}
